<template>
  <div class="expanding-row w-1/5 max-w-full">
    <el-row class="pb-2">
      <el-col :span="12">
        <span v-text="$t('pages.counting_protocols.subtable.starting_float')" />
      </el-col>
      <el-col :span="12" class="text-right">
        <span class="font-bold">{{ totalCalculated }}</span>
      </el-col>
    </el-row>

    <div class="divider-section pb-2">
      <div
        class="mt-2 mb-1"
        v-text="$t('pages.counting_protocols.subtable.counted')"
      />
      <el-row v-for="item in currencyFigures" :key="item">
        <el-col :span="12">
          <span v-text="localizeCurrency(item)" />
        </el-col>
        <el-col :span="12" class="text-right">
          <span>{{ row[item] }}</span>
        </el-col>
      </el-row>
    </div>

    <div class="divider-section py-2">
      <el-row>
        <el-col :span="12">
          <span v-text="$t('pages.counting_protocols.subtable.calculated')" />
        </el-col>
        <el-col :span="12" class="text-right">
          <span class="font-bold">
            {{ $formatCurrency(row.total_counted, row.currency_iso_code) }}
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <span v-text="$t('pages.counting_protocols.subtable.discrepancy')" />
        </el-col>
        <el-col :span="12" class="text-right">
          <span
            class="font-bold"
            :class="{ 'text-th-red': row.discrepancy_total < 0 }"
          >
            {{ $formatCurrency(row.discrepancy_total, row.currency_iso_code) }}
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      currencyFigures: [
        '500',
        '200',
        '100',
        '50',
        '20',
        '10',
        '5',
        '2',
        '1',
        '0.5',
        '0.2',
        '0.1',
        '0.05',
        '0.02',
        '0.01'
      ]
    }
  },
  computed: {
    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },
    totalCalculated() {
      return this.row.total_calculated
        ? this.$formatCurrency(this.row.total_calculated, this.currency)
        : '–'
    },
    currency() {
      return this.row.currency_iso_code || this.defaultCurrency
    }
  },
  methods: {
    localizeCurrency(amount = 0) {
      if (!this.currency) return '–'
      return this.$formatCurrency(amount, this.currency)
    }
  }
}
</script>

<style scoped>
.expanding-row {
  min-height: 3rem;
}

.divider-section {
  border-top: 1px solid var(--border-color);
}
</style>
