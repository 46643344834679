<template>
  <th-page-wrapper>
    <th-datatable
      ref="resourceTable"
      :key="$route.fullPath"
      class="counting-protocols"
      do-route-filters
      expanding-row
      headers-filterable
      no-meta-check
      resource="analytics"
      route-base="/reports/financial_accounting/counting_protocols"
      show-filter
      sortable
      transform-fetched-meta-allowed
      :apply-cell-class-name="applyDiscrepancyColumnClass"
      :buttons="computedButtons"
      :custom-resource="countingProtocolsResource"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers-config="headersConfig"
      :headers="headers"
      :locale="locale"
      :prune-search-filters="pruneSearchFilters"
      :resource-limit="1000"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="false"
      :show-summary="false"
      :transform-fetched-data="transformFetchedData"
      @headers-config="handleHeadersConfig"
    >
      <template #expanding-row="{ row }">
        <expanding-row :row="row" />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import qs from 'qs'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import flush from 'just-flush'
import ExpandingRow from './components/expanding-row'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import { useExportsStore } from '@/store/exports'

const headersConfigPath = 'settings.headerFilters.counting_protocols'

export default {
  name: 'CountingProtocols',
  metaInfo() {
    return {
      title: this.$t('pages.counting_protocols.title')
    }
  },
  components: {
    ExpandingRow
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', elegibleObj)

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      countingProtocolsResource: th.analyticsHandlers().analytics.reports
        .AnalyticsReportsCountingProtocols,
      locale: this.$i18n.locale,
      headers: [
        {
          field: 'register_custom_id',
          label: this.$t('pages.counting_protocols.all.headers.register_id'),
          truncate: true,
          minWidth: 100,
          fallback: '-',
          sortType: 'number'
        },
        {
          field: 'created_at',
          label: this.$t('pages.counting_protocols.all.headers.date'),
          minWidth: 160,
          truncate: true,
          formatter: (row) =>
            this.$date.formatDateTimeWithTimezone(row.created_at),
          fallback: '-'
        },
        {
          field: 'branch_custom_id',
          label: this.$t('pages.counting_protocols.all.headers.branch'),
          fallback: '-',
          truncate: true,
          minWidth: 100,
          sortType: 'number'
        },
        {
          field: 'custom_id',
          label: this.$t('pages.counting_protocols.all.headers.balance_number'),
          align: 'right',
          truncate: true,
          minWidth: 160,
          fallback: '-',
          sortType: 'number'
        },
        {
          field: '_counting_type_label',
          label: this.$t(
            'pages.counting_protocols.all.headers.counting_type.title'
          ),
          truncate: true,
          minWidth: 140
        },
        {
          field: '_cashier_staff_label',
          label: this.$t('pages.counting_protocols.all.headers.cashier'),
          fallback: '-',
          truncate: true,
          minWidth: 160
        },
        {
          field: 'total_counted',
          label: this.$t('pages.counting_protocols.all.headers.balance'),
          fallback: '-',
          align: 'right',
          truncate: true,
          minWidth: 150,
          formatter: (row) => {
            if (Number.isFinite(row.total_counted)) {
              return this.$formatCurrency(
                row.total_counted,
                row.currency_iso_code
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'discrepancy_total',
          label: this.$t('pages.counting_protocols.all.headers.discrepancy'),
          align: 'right',
          truncate: true,
          minWidth: 140,
          fallback: '-',
          formatter: (row) => {
            if (Number.isFinite(row.discrepancy_total) && row.has_discrepancy) {
              return this.$formatCurrency(
                row.discrepancy_total,
                row.currency_iso_code
              )
            }
            return '-'
          },
          sortType: 'currency'
        }
      ],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_financial_accounting:counting_protocols:export'],
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      timeZone: 'Config/getTimeZone',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    resourceQuery() {
      return {
        query: {
          branch: this.currentLocation || undefined,
          only_discrepancies:
            [true, 'true'].includes(this.parsedQuery.only_discrepancies) ||
            undefined,
          limit: 100
        }
      }
    },
    headersConfig() {
      return safeGet(this.localConfiguration, headersConfigPath, {})
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    filtersList() {
      return [
        {
          name: 'cashier_staff',
          type: 'remote-search-select',
          label: this.$t('pages.counting_protocols.all.headers.cashier'),
          resource: 'staff',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          label: this.$t('pages.counting_protocols.all.headers.date'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        },
        {
          name: 'only_discrepancies',
          type: 'switch',
          label: this.$t(
            'pages.counting_protocols.all.filters.only_discrepancies'
          ),
          text: {},
          formatValue: (value) =>
            value === undefined || value === false || value === 'false'
              ? this.$t('common.interactions.buttons.no')
              : this.$t('common.interactions.buttons.yes')
        }
      ]
    },
    translations() {
      return {
        cashing_up: this.$t(
          'pages.counting_protocols.all.headers.counting_type.types.cashing_up'
        ),
        closing: this.$t(
          'pages.counting_protocols.all.headers.counting_type.types.closing'
        ),
        opening: this.$t(
          'pages.counting_protocols.all.headers.counting_type.types.opening'
        ),
        wallet_open: this.$t(
          'pages.counting_protocols.all.headers.counting_type.types.wallet_open'
        ),
        wallet_close: this.$t(
          'pages.counting_protocols.all.headers.counting_type.types.wallet_close'
        )
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    pruneSearchFilters(filters) {
      if (filters.only_discrepancies === 'true') {
        // NOTE: only_discrepancies is only a frontend filter which is not matching a specific API filter in cashier_counting_protocols v2.
        // We're using this filter to explicit filter only for data with discrepancies i.e. "has_discrepancy = true". When "only_discrepancies = false",
        // we don't want to have has_discrepancy at all in the query params since "has_discrepancy = false" will fetch the wrong data, i.e. only data with *no* discrepancies, which is not desired.
        filters.only_discrepancies = undefined
        filters.has_discrepancy = 'true'
      }
      return filters
    },
    refresh() {
      this.$refs.resourceTable.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.counting_protocols.title')
        })
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    applyDiscrepancyColumnClass: ({ row, column }) => {
      if (column.property !== 'discrepancy_total' || !row.has_discrepancy)
        return ''
      return row.discrepancy_total > 0 ? 'positive' : 'negative'
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    transformFetchedData(data) {
      return data.map((item) => {
        // Remove discrepancy total for sorting
        if (!item.has_discrepancy) {
          item.discrepancy_total = null
        }

        item._counting_type_label = this.translations[item.counting_type] || '-'

        if (!item.cashier && !item.cashier_number) {
          item._cashier_staff_label = '-'
        } else {
          item._cashier_staff_label = flush([
            item.cashier_number,
            item.cashier
          ]).join(' - ')
        }

        // Needed for expanding row
        item.id = item._id

        return item
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...resourceOptions.query,
        format: 'csv',
        filename_prefix: this.$t(
          'pages.counting_protocols.all.exports.filename_prefix'
        ),
        timezone: this.timeZone || 'Europe/Berlin'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlersV3()
          .analytics.reports.AnalyticsReportsCountingProtocols.export({ query })

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.counting_protocols.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV3',
              path: 'analytics.reports.AnalyticsReportsCountingProtocols',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.counting_protocols.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.counting-protocols :deep(.positive .cell) {
  color: var(--primary-color);
}

.counting-protocols :deep(.negative .cell) {
  color: var(--error-color);
}
</style>
